import { extend, configure } from 'vee-validate';
import i18n from '@/i18n';

import {
  required,
  // eslint-disable-next-line
  required_if,
  // eslint-disable-next-line
  max_value,
  // eslint-disable-next-line
  min_value,
  confirmed,
  numeric,
  length,
  email,
  size,
  regex
} from 'vee-validate/dist/rules';

import { UserStore } from '@/store/modules';
// eslint-disable-next-line
const rules: { [key: string]: any } = {
  required,
  // eslint-disable-next-line
  required_if,
  // eslint-disable-next-line
  max_value,
  // eslint-disable-next-line
  min_value,
  numeric,
  email,
  confirmed,
  length,
  size,
  regex
};

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values): string => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values).toString();
  }
});

for (const rule of Object.keys(rules)) {
  extend(rule, {
    ...rules[rule],
    message: (_, values) => i18n.t(`validations.messages.${rule}`, values)
  });
}
extend('checkUsernameExists', {
  validate: async value => !(await UserStore.checkUsername(value)),
  message: 'User with this username already exists'
});
