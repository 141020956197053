import { UserTypes } from '@/store/models/user';
import { JwtStore } from '@/store/modules';

export function roleChecker(roles: UserTypes[]) {
  for (const role of roles) {
    if (JwtStore.UserDetail.customUser.role === role) {
      return true;
    }
  }
  return false;
}
export function isCurrentUser(userId: number) {
  if (JwtStore.UserDetail.id === userId) {
    return true;
  }
  return false;
}

export function isSuperuser() {
  return JwtStore.UserDetail.isSuperuser;
}

export async function WaitUntilRefreshed() {
  while (JwtStore.RefreshingToken) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
}

export function HasJwtExpired() {
  const exp = JwtStore.DecodedPayload.exp;
  const now = Math.floor(new Date().getTime() / 1000);
  if (exp <= now) {
    return true;
  }
  return false;
}
