import { FarmStore } from '@/store/modules';

export default new (class Helper {
  getNestedFormData(
    formData: FormData,
    // eslint-disable-next-line
    data: any,
    prekey: string | null = null
  ) {
    for (const key of Object.keys(data)) {
      let dataKey = key;
      if (prekey) {
        dataKey = `${prekey}[${key}]`;
      } else if (Array.isArray(data)) {
        dataKey = `[${dataKey}]`;
      }
      if (typeof data[key] == 'object') {
        if (data[key] instanceof File) {
          formData.append(dataKey, data[key]);
        } else if (data[key] === null) {
          // pass
        } else {
          formData = this.getNestedFormData(formData, data[key], dataKey);
        }
      } else {
        formData.append(dataKey, data[key]);
      }
    }
    return formData;
  }

  csvToArray(str: string, delimiter = ',') {
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delimiter)
      .map(x => x.trim());

    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = rows.map(function(row) {
      const values = row.split(delimiter);
      // eslint-disable-next-line
      const el = headers.reduce(function(object: any, header, index) {
        let value = values[index];
        if (value) {
          value = value.trim();
        } else {
          value = '';
        }
        object[header] = value;
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

  // eslint-disable-next-line
  wrapCsvValue(val: any, formatFn?: any) {
    let formatted = formatFn !== void 0 ? formatFn(val) : val;

    formatted =
      formatted === void 0 || formatted === null ? '' : String(formatted);

    formatted = formatted.split('"').join('""');
    /**
     * Excel accepts \n and \r in strings, but some other CSV parsers do not
     * Uncomment the next two lines to escape new lines
     */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`;
  }

  syncSleep(ms: number) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > ms) {
        break;
      }
    }
  }

  async sleep(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }

  async fetchingDefaultFarm() {
    while (FarmStore.FetchingDefaultFarm) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  thousandSeparator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (
      amount !== undefined ||
      amount !== 0 ||
      amount !== '0' ||
      amount !== null
    ) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return amount;
    }
  }
})();
